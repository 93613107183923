<template>
  <div class="lefthead">
    <h1>おもしろ便利,開発します</h1>
    <br />
    <ul>
      <li v-for="oo in list">{{ oo }}</li>
    </ul>
  </div>
</template>

<script>
import Hooper from "../../components/hooperContent.vue";
export default {
  name: "lefthead",
  components: {
    Hooper,
  },
  data() {
    return {
      list: [
        "新規案件の仕様策定からプリプロダクション〜本制作まで",
        "ご計画のフェーズに合わせてお見積りいたします",
        "",
      ],
    };
  },
};
</script>

<style scoped>
ul {
  list-style-type: none;
}
h1 {
  color: #f80;
  margin-top: 20px !important;
}
div {
  padding: 8px;
  position: relative;
}

p:first-child {
  color: #667eea;
}
.lefthead {
  min-height: 400px;
}
p {
  text-align: justify;
  letter-spacing: 3px;
  line-height: 1.6;
}
.Stars {
  background-color: #334155;
}
</style>
