<template>
  <div class="slide">
    <carousel :settings="config">
      <Slide v-for="slide in slides" :key="slide.name">
        <div class="carousel__item">
          <h1 class="slide" v-bind:style="{ color: slide.color }">
            {{ slide.name }}
          </h1>
          <p v-for="(str, index) in slide.descs" :key="index">{{ str }}</p>
        </div>
      </Slide>
      <template #addons>
        <navigation />
        <pagination />
      </template>
    </carousel>
  </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

export default {
  name: "HooperContent",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      config: {
        wrapAround: true,
        itemsToShow: 1,
        autoplay: 9000,
        vertical: true,
      },
      slides: [
        {
          name: "NFTはじめました",
          color: "#5d81fe",
          descs: [
            "これからますます広がるNFTによるコンテンツ価値の広がりと経済圏",
            "アートとコードが融合したNFTはクリエイターだけでなくコンテンツホルダー企業にとっても",
            "大きなチャンスであり待望のソリューションです。",
            "いっしょに新たな時代の価値創造にチャレンジしませんか",
          ],
        },

        {
          name: "Dev. Assistant",
          color: "#00e070",
          descs: [
            "PM.QA業務のアウトソーシング",
            "開発開始前に仕様をかためるお手伝い〜開発中の工程管理やQC業務まで",
            "お客様の代わりにオフショア開発の管理業務を代行いたします。",
            "遅延や修正でコスト高に変貌したラボプロジェクトのテコ入れにも是非",
          ],
        },
        {
          name: "Video Compilation",
          color: "#00e0e0",
          descs: [
            "お客様からのオーダーと素材をもとに",
            "youTube向け動画編集を行います",
            "オリジナルのジングル・BGM楽曲や",
            "ベトナム人ボイスアクターによるナレーションも可能",
          ],
        },
        {
          name: "Backoffice Support",
          color: "#0070e0",
          descs: [
            "ベトナムのパートナー弁護士事務所と連携し",
            "会社設立やVISA取得等の処理をサポートします",
            "設立の際の事務所や駐在される住まい探しなどのご要望にもお答えいたします",
          ],
        },
        {
          name: "Research Agency",
          color: "#b22116",
          descs: [
            "（コロナ限定)",
            "渡航が困難で高コストなコロナ下,お客様に代わりベトナム現地でONLINEお使いをいたします",
            "調査前の事前リサーチなどもお打ち合わせの上,現地調査には通訳を同行し現地で協議、交渉を行います",
            "余計な渡航を減らすことはコロナ対策に厳格に対処しているベトナムの方々に報いることでもあります。",
          ],
        },
      ],
    };
  },
};
</script>

<style>
div.slide {
  .carousel__track {
    width: 100%;
    height: 300px;
    transition: 1000;
    background-color: #f4fff8;
    li.carousel__slide {
    }
  }
}

h1.slide {
  font-size: 60px;
}
</style>
