<template>
  <div>
    <h2 class="text-center fw-bold mt-5 mb-4">Tech</h2>
    <div class="row">
      <div class="col-sm">
        <div class="row text-center mt-5 me-sm-5">
          <div class="col-sm col-md-3" v-for="skill in skills">
            <div
              class="
                lang-icon
                col-sm col-md-3
                fs-3
                d-inline
                p-2
                shadow
                bg-light
                rounded-circle
              "
            >
              <i :v-if="isDevi(skill)" :class="devi(skill)" />
              <fa :icon="getIcon(skill)" class="text-other" />
            </div>
            <div class="pt-3">
              <h5 class="fw-bold">{{ skill.name }}</h5>
              <p class="fs-6 text-secondary">{{ skill.desc }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- content cards -->
    <div class="row mt-5">
      <h2 class="text-center fw-bold mt-4 mb-3">制作コンテンツ</h2>
      <div class="col-sm-3 up mb-4" v-for="(card, index) in cards" :key="index">
        <div class="card border-0">
          <div class="card-body bg-pink rounded-bottom text-light">
            <h3 class="card-title">{{ card.name }}</h3>
            <p class="card-text card-p2 text-center">
              {{ card.desc }}
            </p>
          </div>
          <img :src="card.pic" class="content card-img-top" alt="..." />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
export default {
  name: "Section",
  methods: {
    isDevi(oo) {
      if (oo.devi == "") return oo.false;
      return true;
    },
    devi(oo) {
      return oo.devi;
    },

    getIcon(oo) {
      console.log(oo);
      if (!oo) return "";
      return oo.icon;
    },
  },
  data() {
    return {
      cards: [
        {
          name: "PS5",
          desc: "ゲーム開発/UI",
          pic: require("@/assets/images/contents/na.png"),
        },
        {
          name: "FastFix",
          desc: "タスクマネジメントシステム",
          pic: require("@/assets/images/contents/fastfix.png"),
        },

        {
          name: "App",
          desc: "Android/iOS ゲームアプリ開発",
          pic: require("@/assets/images/contents/na.png"),
        },
        {
          name: "PS3",
          desc: "ゲーム開発",
          pic: require("@/assets/images/contents/na.png"),
        },
        {
          name: "アーケード",
          desc: "通信モジュール開発",
          pic: require("@/assets/images/contents/na.png"),
        },
        {
          name: "Steam ",
          desc: "ゲームアプリ開発",
          pic: require("@/assets/images/contents/na.png"),
        },
        {
          name: "オープンデータ研究所",
          desc: "書籍連動データ変換アプリ",
          pic: require("@/assets/images/contents/doctor.png"),
        },
        {
          name: "SNSバイラルゲーム",
          desc: "flash 鉄道ゲーム",
          pic: require("@/assets/images/contents/na.png"),
        },

        {
          name: "Cross library",
          desc: "iOS,Android 用 ２Ｄ・サウンドクロスライブラリ",
          pic: require("@/assets/images/contents/spin.png"),
        },
        {
          name: "KPIツール",
          desc: "SNSゲーム用API＆KPIツール",
          pic: require("@/assets/images/contents/kpi.png"),
        },
        {
          name: "PSP",
          desc: "PSPゲーム開発（シミュレーション)にPGで参加",
          pic: require("@/assets/images/contents/na.png"),
        },
        {
          name: "CMS",
          desc: "CMS/サイト開発（Django)",
          pic: require("@/assets/images/contents/na.png"),
        },
      ],
      skills: [
        {
          name: "C/C++",
          icon: ["fas", ""],
          devi: "devicon-cplusplus-plain",
          desc: "ゲーム制作/ツール開発",
        },
        {
          name: "C#",
          icon: ["fas", ""],
          devi: "devicon-csharp-plain",
          desc: "ゲーム制作(Unity)",
        },
        {
          name: "Unity",
          icon: ["fab", ""],
          devi: "devicon-unity-original",
          desc: "ゲーム、映像制作",
        },
        {
          name: "Python",
          icon: ["fas", ""],
          devi: "devicon-python-plain",
          desc: "Webアプリ(Django)やツール開発",
        },
        {
          name: "GO",
          icon: ["fab", "devicon-go-plain"],
          devi: "devicon-go-plain",
          desc: "Webアプリ(Django)やツール開発",
        },
        {
          name: "node-js",
          icon: ["fab", ""],
          devi: "devicon-nodejs-plain",
          desc: "Webアプリ/バックエンド",
        },
        {
          name: "MongoDB",
          icon: ["fab", ""],
          devi: "devicon-mongodb-plain",
          desc: "Webアプリ bakcent",
        },
        {
          name: "Django",
          icon: ["fab", ""],
          devi: "devicon-django-plain",
          desc: "Python, backend",
        },
        {
          name: "Vue 3",
          icon: ["fab", ""],
          devi: "devicon-vuejs-plain",
          desc: "js, frontend",
        },
        {
          name: "OpenGL",
          icon: ["fab", ""],
          devi: "devicon-opengl-plain",
          desc: "node, backend",
        },
        {
          name: "Google Scripts",
          icon: ["fab", ""],
          devi: "devicon-google-plain",
          desc: "node, backend",
        },
      ],
    };
  },
  setup() {},
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Festive&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Festive&family=Varela+Round&display=swap");
.lang-icon {
  color: #f8a;
}
div.card {
  max-height: 460px;
  img.content {
    max-width: 100%;
    max-height: 300px;
    object-fit: cover !important;
    object-position: 0 0 !important;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.up {
  cursor: pointer;
}
.up img:hover {
  opacity: 0.4;
}
.up:hover {
  padding-top: 0px;
}

.Computercard {
  width: 70%;
  margin-left: 19%;
  box-shadow: 5px 4px 3px rgb(180, 180, 180);
}
.card-p {
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}
.text-pin {
  color: #334155;
}
.text-other {
  color: #7952b3;
}
.bg-other {
}
.Computercardbody {
  background-color: #364d74;
}
.bg-pink {
  background-color: rgba(100, 100, 200, 0.2);
}
.bg-pink > h3 {
  text-shadow: 2px 2px 4px #000000;
}
.card-p2 {
  color: #444;
}
h2 {
  color: #fff;
}
</style>
