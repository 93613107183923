<template>
  <div>
    <img :src="imgsrc" alt="header image" />
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  name: "righthead",
  setup() {
    // Image source
    let imgsrc;

    try {
      //Check availability
      imgsrc = ref(require("@/assets/images/tech2.png"));
    } catch (error) {
      console.error(error);
    }
    return {
      imgsrc,
    };
  },
};
</script>

<style scoped>
img {
  margin-top: 180px;
  width: auto;
  max-height: 1200px;
}
</style>
